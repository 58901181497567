<template>
    <nav class="navbar navbar-default" role="navigation" style="">

        <div id="menu" class="menu" :class="{'open': showMobileMenu}">
            <nav class="main-nav">
                <ul>
                    <li>
                        <router-link to="/">Home</router-link>
                    </li>
                    <li>
                        <router-link to="/work">Work</router-link>
                    </li>
                    <li>
                        <router-link to="/news">News</router-link>
                    </li>
                    <li>
                        <router-link to="/contact">Contact</router-link>
                    </li>
                </ul>
            </nav>

        </div>


        <div class="container">
            <div class="row">
                <div class="navbar-header page-scroll">
                    <router-link to="/" class="logo">
                        <img src="../assets/img/logo.png" width="130" alt="logo"/>
                        <p>OCEAN RING TECHNOLOGIES</p>
                    </router-link>
                </div>

                <div class="pull-right">
                    <button class="toggle-menu" :class="{'active': showMobileMenu}"
                            @click="showMobileMenu = !showMobileMenu">
                        <span></span>
                    </button>

                    <ul class="navigation-bar">
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li>
                            <router-link to="/work">Work</router-link>
                        </li>
                        <li>
                            <router-link to="/news" :class="{'router-link-exact-active router-link-active': routerActive}">News</router-link>
                        </li>
                        <li>
                            <router-link to="/contact">Contact</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
  export default {
    name: "OrtNavBar",
    data() {
      return {
        showMobileMenu: false,
        routerActive: false
      }
    },
    created() {
      this.routerActive = this.$route.name === 'news-details';
    },
    watch: {
      '$route'(to) {
        this.showMobileMenu = false;
        this.routerActive = to.name === 'news-details';
      }
    }
  }
</script>

<style scoped lang="scss">
    #header {
        padding: 2rem;
    }

    // It actually starts here

    $menu-bg: #0556a1;
    $menu-color: #ffffff;
    $menu-font-size: 2.5rem;
    $menu-zindex: 1100;
    $hamburger-color: #fff;
    $hamburger-width: 50px;
    $hamburger-height: 50px;
    $hamburger-zindex: $menu-zindex + 10;


    #menu {
        background: $menu-bg;
        height: 100%;
        left: 0;
        opacity: 0;
        overflow: hidden;
        position: fixed;
        text-align: center;
        top: 0;
        transition: all 0.7s ease-in-out;
        // For some sweet fadez
        visibility: hidden;
        width: 100%;
        z-index: $menu-zindex;

        &.open {
            opacity: 1;
            visibility: visible;

            @for $i from 1 through 10 {
                li:nth-child(#{$i}) {
                    animation-delay: $i*0.1s;
                }
            }

            li {
                animation-duration: 1.0s;
                animation-fill-mode: both;
                animation-name: fadeInUp;
            }
        }

        ul {
            &:hover {
                a {
                    opacity: 0.5;
                }
            }

            a {
                // Stops it being a twitchy mess in chrome :pray:
                // https://www.binarymoon.co.uk/2014/02/fixing-css-transitions-in-google-chrome/
                -webkit-transform: translateZ(0);
                transition: opacity 0.3s ease-in-out;

                &:hover {
                    opacity: 1;
                }
            }
        }

        .main-nav {
            position: relative;
            top: 50%;
            transform: translateY(-50%);

            ul {
                list-style: none;
                margin: 0;
                padding: 0;
            }

            a {
                color: $menu-color;
                display: block;
                font-size: $menu-font-size;
                font-weight: bold;
                padding: 1rem 1rem;
                text-decoration: none;
                text-transform: uppercase;

                &:first {
                    padding-top: 0;
                }
            }
        }

        .menu-footer {
            padding: 2rem;
            bottom: 0;
            left: 0;
            position: absolute;
            right: 0;

            ul {
                display: inline-block;
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    display: inline;
                    margin: 0 1rem;
                }
            }

            a {
                color: $menu-color;
                text-decoration: none;
                text-transform: uppercase;
            }
        }
    }


    // Hamburger menu toggler stuff below
    .toggle-menu {
        display: none;
        background-color: transparent;
        border: none;
        cursor: pointer;
        float: right;
        height: $hamburger-height;
        outline: none;
        padding: 0;
        pointer-events: initial;
        position: relative;
        vertical-align: middle;
        width: $hamburger-width;
        z-index: $hamburger-zindex;

        span {
            background-color: $hamburger-color;
            content: "";
            display: block;
            height: 2px;
            left: calc(50% - 13px);
            position: absolute;
            top: calc(50% - 1px);
            transform-origin: 50% 50%;
            transition: background-color 0.2s ease-in-out, top 0.2s 0.2s ease-out, transform 0.2s linear;
            width: 26px;

            &:before, &:after {
                background-color: $hamburger-color;
                content: "";
                display: block;
                height: 2px;
                position: absolute;
                transform-origin: 50% 50%;
                transition: background-color 0.2s ease-in-out, top 0.2s 0.2s ease-out, transform 0.2s linear;
                width: 26px;
            }

            &:before {
                top: 7px;
            }

            &:after {
                top: -7px;
            }
        }

        &.active {
            span {
                background-color: transparent;
                transition: background 0.2s ease-out;

                &:before, &:after {
                    transition: top 0.2s ease-out, transform 0.2s 0.2s ease-out;
                }

                &:before {
                    top: 0;
                    transform: rotate3d(0, 0, 1, -45deg);
                }

                &:after {
                    top: 0;
                    transform: rotate3d(0, 0, 1, 45deg);
                }
            }
        }
    }

    @media (max-width: 768px) {
        .toggle-menu {
            display: inline-block;
        }
        .pull-right {
            position: absolute;
            top: 20px;
            right: 0;
        }
    }

</style>
