<template>
  <div class="row" id="contact-footer">
    <div class="col-md-3 col-heading">

      <div class='footer-logo'>
        <a href="/"><img src="../assets/img/logo.png" alt="logo" width="130" /><p>OCEAN RING TECHNOLOGIES</p> </a>
      </div>

    </div>
    <div class="col-md-3 col-pad">
      <h3 class="wow fadeInDown animated">About</h3>
      <p class="text-light wow fadeInUp animated">We are a software solutions company helping clients develop, manage and modernize applications to accelerate their digital transformation and business results.</p>
    </div>

    <div class="col-md-3 col-pad">
      <h3 class="wow fadeInDown animated">Location</h3>
      <ul type="none" class="wow fadeInUp animated">
        <li><i class="fa fa-map-marker"></i> 701 S. 50th St <br />Philadelphia, PA</li>
      </ul>
    </div>

    <div class="col-md-3 col-pad">
      <h3 class="wow fadeInDown animated">Social</h3>
      <ul type="none" class="wow fadeInUp animated">
        <li><i class="fa fa-envelope"></i> &#105;&#110;&#102;&#111;&#64;&#111;&#99;&#101;&#97;&#110;&#114;&#105;&#110;&#103;&#116;&#101;&#99;&#104;&#46;&#99;&#111;&#109;</li>
        <li><i class="fa fa-phone"></i> &#40;&#56;&#53;&#54;&#41;&#32;&#52;&#52;&#56;&#45;&#52;&#51;&#54;&#56;</li>
        <li>&nbsp;</li>
      </ul>
      <ul class="social wow fadeInUp animated" type="none">
        <li><a href="https://www.facebook.com/oceanringtech/" rel="noopener" target="_blank"><i class="fa fa-facebook"></i></a></li>
        <li><a href="https://twitter.com/oceanringtech" rel="noopener" target="_blank"><i class="fa fa-twitter"></i></a></li>
        <li><a href="https://www.linkedin.com/company/14395475" rel="noopener" target="_blank"><i class="fa fa-linkedin"></i></a></li>
        <li><a href="https://www.instagram.com/oceanringtech/" rel="noopener" target="_blank"><i class="fa fa-instagram"></i></a></li>
      </ul>
    </div>
  </div>
</template>

<script>
    export default {
        name: "OrtFooter"
    }
</script>

<style scoped>

</style>
