<template>
    <div id="pagewrap" class="pagewrap container-fluid">
        <ort-nav-bar/>
        <router-view/>
        <ort-footer/>
    </div>
</template>

<script>
    import OrtNavBar from "./OrtNavBar";
    import OrtFooter from "./OrtFooter";
    require('../assets/style.css');

    export default {
        name: "Default",
        components: {
            OrtFooter,
            OrtNavBar
        }
    }
</script>

<style scoped>
  /*@import "../assets/style.css";*/
</style>
